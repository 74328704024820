body {
    background-color: #000;
    color: #fff;
}


a{
  font-size: 20px;
}
h2{
  font-size: 2.5rem;
}
h3{
  font-size: 2.0rem;
}
.navbar{
display: flex;
height: 100px;
width: 100%;
img{
width: 100%;
height: auto;
max-width: 100px;
}

}
.nav ul{
display: flex;
list-style: none;
margin-top: 20px;
span{
color: #20c997;
}

}
.nav li{
margin-right: 20px;
text-transform: uppercase;
span{
    color: #fff;
}
}


@media only screen and (max-width:1024px){



}

@media only screen and (max-width:375px){
button {
    font-size: 12px;
}
}

.headingsection{
  text-align: center;
  margin-bottom: 50px;
}
.banner-area{
    padding: 145px 0;
    position: relative;
   
    &::before{
      position: absolute;
      content: "";
      height: 100%;
      width: 100%;
      background: var(--black-mode);
      opacity: 0.7;
      min-height: 100vh;
      top: -73px;
      z-index: -1;
     }
   .bannerbg {
      position: absolute;
      top: 0;
      max-width: 700px;
      right: 0;
  }
 

 

   


   

  
}

.textsection {
  display: flex;
  flex-direction: column;

  /* text-align: center; */
}

.imagesection {
  display: flex;
  flex-direction: column;
  align-items: center;
  img{
   max-width: 500px;
   padding: 20px;
  }
}

.flayerimage, .slayerimage {
  display: flex;
  align-items: center;
}

.arrowsection {
  display: flex;
  justify-content: center;
  margin: 10px 0;  /* Adjust margin as needed */
}


.arrowf{
  filter: invert(1);
}
.arrows{
  filter: invert(1);
  transform: rotate(89);
}
.arrowt{
  filter: invert(1);
  transform: rotate(179deg);
}
img.arrows {
  transform: rotate(91deg);
  /* position: absolute; */
  margin-left: 500px;
}




.alice-carousel__stage {
  position: relative;
  box-sizing: border-box;
  width: 100%;
  height: 100%;
  margin: 0;
  padding: 0;
  white-space: nowrap;
  transform-style: flat;
  -webkit-transform-style: flat;
  backface-visibility: hidden;
  -webkit-backface-visibility: hidden;
  mix-blend-mode: lighten;
  }
  

.plan-area {
  padding: 10px 0;
  position: relative;
  overflow: hidden;
  background-color: rgb(15 22 27);
  padding-top: 50px;
  padding-bottom: 50px;
}
.planbg {
position: absolute;
top: -300px;
max-width: 1000px;
z-index: -1;
transform: rotate(84deg);
}

.plantittlesection {
display: flex;
justify-content: space-between;
margin: 20px 0;
}
.plancardsection {
display: flex;
}


.plancard {
height: auto;
padding: 20px;
border-radius: 10px;
display: flex;
flex-wrap: wrap;
justify-content: center;
align-items: center;
border: 1px solid #fff;
margin-top: 50px;

h3 {

 
  border: 1px solid gray;
  border-radius: 7px;
  width: 100%;
  height: auto;
  max-width: 200px;
  font-size: 17px;
  font-weight: 600;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #38a081;
  text-align: center;
  padding: 2px;
}
img{
width: 100%;
height: auto;
border-radius: 10px;
margin-bottom: 10px;

}
}
.react-player video {
  max-width: 100%;
}
.button-prev, .button-next {
width: 50px;
height: 50px;
background-color: #333;
display: inline-flex;
align-items: center;
justify-content: center;
border-radius: 50%;
cursor: pointer;
margin: 0 5px;
transition: background-color 0.3s ease;
}

.button-prev:hover, .button-next:hover {
  background: linear-gradient(264.28deg, #fffc96 -38.2%, #432507 103.12%);
}

.icon {
width: 24px;
height: 24px;

}
.plantittlesection {
  display: flex;
  justify-content: space-between; /* Adjust as needed to give space between the title and buttons */
  align-items: center;
}





.custom-arrow-button {
  background-color: #bd6cd9; 
  border: none;         
  border-radius: 50%;         
  padding: 15px;              
  cursor: pointer;           
  transition: 0.3s;  
     
}

.custom-arrow-button:hover {
  background-color: #dec7ff;  // Change background color on hover
  transform: scale(1.1);      // Scale the button a bit on hover
}
.arrowicon{
  color: #fff;
}
.copybutton {
  margin-left: 10px;  /* adjust this value as needed */
}
.copybutton{
  button{
    font-size: 12px;
  }
}


@media only screen and (max-width:768px){
  .copylink{
    span{

      font-size: 10px;
    }
    button{
      font-size: 10px;
    }
  }
  a{
    font-size: 15px;
  }
  h2{
    font-size: 2rem;
  }
  h3{
    font-size: 1.5rem;
  }



}